<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="4">
        System Administrator
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
name: "SysAdmin"
}
</script>

<style scoped>

</style>